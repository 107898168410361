@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600&family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=PT+Serif:ital,wght@1,700&family=Roboto+Mono:wght@400;700&family=Roboto:ital,wght@1,300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Trocchi&display=swap');

html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: goldenrod transparent;
}


::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
body {
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #c4c3ca;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: all 300ms linear;
}

.navbar-black {
  overflow-x: visible !important;
  background-color: #000;
  transition: background-color 0.3s ease;
}

/* #Navigation */
.cd-header {
  overflow-x: visible !important;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-wrapper {
  overflow-x: visible !important;
  position: relative;
  width: calc(100% - 65px);
  margin-left: 35px;
}

.logo-wrap {
  overflow-x: visible !important;
  position: absolute;
  display: block;
  left: 0;
  top: 25px;
  cursor: pointer;
}

.logo-wrap img {
  overflow-x: visible !important;
  width: 15vh; /* Use vw instead of vh for responsiveness */
  padding-top: 7.5%;
  height: auto;
  filter: brightness(3%) invert(100%);
}

.logo-wrap a {
  overflow-x: visible !important;
  cursor: pointer;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 900 !important;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  transition: all 0.3s ease-out;
}

.logo-wrap a span {
  overflow-x: visible !important;
  color: #8167a9;
}

.logo-wrap a:hover {
  overflow-x: visible !important;
  opacity: 0.9;
}
.buttons-grid {
  overflow-x: visible !important;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: center;
}

.language-button {
  font-family: "Roboto", sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 35px;
  font-weight: 100;
  color: #fff;
  padding-top: 22px;
}

.menu-icon {
  overflow-x: visible !important;
  cursor: pointer;
}

.nav-but-wrap {
  overflow-x: visible !important;
  position: relative;
  display: inline-block;
  float: right;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 62px;
  margin-top: 10px;
  transition: all 0.3s ease-out;
}

.menu-icon {
  overflow-x: visible !important;
  height: 20px;
  width: 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: block;
  margin-top: 30px;
}

.menu-icon__line {
  overflow-x: visible !important;
  height: 3px;
  width: 30px;
  display: block;
  background-color: #fff;
  margin-bottom: 7px;
  cursor: pointer;
  transition: background-color 0.5s ease, transform 0.2s ease;
}

.menu-icon__line-left {
  overflow-x: visible !important;
  width: 16.5px;
  transition: all 200ms linear;
}

.menu-icon__line-right {
  overflow-x: visible !important;
  width: 16.5px;
  float: right;
  transition: all 200ms linear;
}

.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
  overflow-x: visible !important;
  width: 30px;
}

@media (max-width: 600px) {
  .logo-wrap img {
    padding-top: 11%;
  }
  .logo-wrap a {
    font-size: 10px;
  }
  .language-button {
    font-size: 30px;
    font-weight: 100;
    color: #fff;
    padding-top: 20px;
  }
  .menu-icon__line {
    height: 1px;
  }
}

.nav {
  overflow-x: visible !important;
  position: fixed;
  z-index: 98;
}

.nav:before,
.nav:after {
  overflow-x: visible !important;
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 21, 26, 0.6);
  border-bottom-left-radius: 200%;
  z-index: -1;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
    border-radius linear 0.8s;
  transform: translateX(100%) translateY(-100%);
}

.nav:after {
  overflow-x: visible !important;
  background: rgb(0, 0, 0);
  transition-delay: 0s;
}

.nav:before {
  overflow-x: visible !important;
  transition-delay: 0.2s;
}

.nav__content {
  overflow-x: visible !important;
  position: fixed;
  visibility: hidden;
  top: 50%;
  margin-top: 20px;
  transform: translate(0%, -50%);
  width: 100%;
  text-align: center;
}

.nav__list {
  overflow-x: visible !important;
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}

.nav__list-item {
  overflow-x: visible !important;
  position: relative;
  display: block;
  transition: opacity 0.2s ease, transform 0.3s ease;
  opacity: 0;
  text-align: center;
  color: #fff;
  overflow: hidden;
  font-family: "Trocchi", serif !important;
  font-size: 8vh;
  font-weight: 900;
  line-height: 1.15;
  letter-spacing: 3px;
  transform: translate(100px, 0%);
  margin-top: 0;
  margin-bottom: 0;
}

.nav__list-item a {
  overflow-x: visible !important;
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  overflow: hidden;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  z-index: 2;
  display: inline-block;
  transition: all 200ms linear;
}

.nav__list-item a:after {
  overflow-x: visible !important;
  position: absolute;
  content: "";
  top: 50%;
  margin-top: -2px;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: goldenrod;
  z-index: 1;
  transition: all 200ms linear;
}

.nav__list-item a:hover:after {
  overflow-x: visible !important;
  height: 4px;
  opacity: 1;
  left: 0;
  width: 100%;
}

.nav__list-item a:hover {
  overflow-x: visible !important;
  color: rgba(255, 255, 255, 1);
}

.nav__list-item.active-nav a {
  overflow-x: visible !important;
  color: rgba(255, 255, 255, 1);
}

body.nav-active .nav__content {
  overflow-x: visible !important;
  visibility: visible;
}

body.nav-active .menu-icon__line {
  overflow-x: visible !important;
  background-color: #fff;
  transform: translate(0px, 0px) rotate(-45deg);
}

body.nav-active .menu-icon__line-left {
  overflow-x: visible !important;
  width: 15px;
  transform: translate(2px, 4px) rotate(45deg);
}

body.nav-active .menu-icon__line-right {
  overflow-x: visible !important;
  width: 15px;
  float: right;
  transform: translate(-3px, -3.5px) rotate(45deg);
}

body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
  overflow-x: visible !important;
  width: 15px;
}

body.nav-active .nav {
  overflow-x: visible !important;
  visibility: visible;
}

body.nav-active .nav:before,
body.nav-active .nav:after {
  overflow-x: visible !important;
  transform: translateX(0%) translateY(0%);
  border-radius: 0;
}

body.nav-active .nav:after {
  overflow-x: visible !important;
  transition-delay: 0.1s;
}

body.nav-active .nav:before {
  overflow-x: visible !important;
  transition-delay: 0s;
}

body.nav-active .nav__list-item {
  overflow-x: visible !important;
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

body.nav-active .nav__list-item:nth-child(0) {
  transition-delay: 0.7s;
}

body.nav-active .nav__list-item:nth-child(1) {
  transition-delay: 0.8s;
}

body.nav-active .nav__list-item:nth-child(2) {
  transition-delay: 0.9s;
}

body.nav-active .nav__list-item:nth-child(3) {
  transition-delay: 1s;
}

body.nav-active .nav__list-item:nth-child(4) {
  transition-delay: 1.1s;
}

body.nav-active .nav__list-item:nth-child(5) {
  transition-delay: 1.2s;
}

body.nav-active .nav__list-item:nth-child(6) {
  transition-delay: 1.3s;
}

body.nav-active .nav__list-item:nth-child(7) {
  transition-delay: 1.4s;
}

body.nav-active .nav__list-item:nth-child(8) {
  transition-delay: 1.5s;
}

body.nav-active .nav__list-item:nth-child(9) {
  transition-delay: 1.6s;
}

body.nav-active .nav__list-item:nth-child(10) {
  transition-delay: 1.7s;
}

.switch-wrap {
  overflow-x: visible !important;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  transform: translateY(-50%);
  width: 100%;
  transition: all 500ms linear;
  margin: 0 auto;
  text-align: center;
}

.switch-wrap h1 {
  overflow-x: visible !important;
  font-weight: 900;
  font-size: 46px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  transition: all 300ms linear;
}

.switch-wrap p {
  overflow-x: visible !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  color: #8167a9;
  text-align: center;
  margin-top: 15px;
}

.switch-wrap p span {
  overflow-x: visible !important;
  position: relative;
}

.switch-wrap p span:before {
  overflow-x: visible !important;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  left: 0;
  bottom: -5px;
  transition: all 300ms linear;
}

.switch-wrap p span:nth-child(2):before {
  overflow-x: visible !important;
  opacity: 0;
}

#switch,
#circle {
  overflow-x: visible !important;
  cursor: pointer;
  transition: all 300ms linear;
}

#switch {
  overflow-x: visible !important;
  width: 60px;
  height: 8px;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #000;
  border-radius: 27px;
  background: #8167a9;
  position: relative;
  display: inline-block;
}

#circle {
  overflow-x: visible !important;
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}

.switched {
  border-color: #8167a9 !important;
  background: #000 !important;
}

.switched #circle {
  overflow-x: visible !important;
  left: 43px;
  background: #000;
}

.navigation-points {
  overflow-x: visible !important;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.nav-point {
  overflow-x: visible !important;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.nav-point.active {
  overflow-x: visible !important;
  background-color: #555;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden;
  background-color: #000;
}

.fade-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out, background-color 3s ease-in-out; /* Adjust the transition duration as needed */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity value for the black overlay */
}

.fade-image.visible {
  opacity: 0.4;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Change the background color to fully transparent */
}

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the height of the progress bar */
  background-color: #00bcd4; /* Adjust the color as needed */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  transition: transform 200ms linear; /* Add a transition on the transform property */
}

/* index.css */

.hero-container {
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 2.5%;
  height: 8px;
  background-color: white;
  justify-content: center;
  justify-self: center;
  align-self: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  opacity: 0.8;
  transition: width 0.1s ease-in-out;
}

.text-container {
  position: absolute;
  justify-content: center; /* Align content at the bottom */
  color: white;
  font-family: "Kanit", sans-serif;
  z-index: 10;
  margin-top: 18vh;
  padding-left: 8%;
}

.text-container p {
  opacity: 0.7;
  font-size: 30px;
  font-family: "DM Sans", sans-serif;
  margin-right: 25%;
  line-height: 1;
  padding-bottom: 5vh;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  width: "100%";
}

.text-container h2 {
  opacity: 0.7;
  padding-bottom: 1%;
  font-size: 80px;
  font-family: "Trocchi", serif;
  margin-right: 5%;
  line-height: 1;
  align-self: flex-end;
}

.text-container .buttonClass {
  padding: 15px 20px;
  background: transparent;
  color: goldenrod;
  border: 2px solid gold;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 30px;
  font-weight: 500;
  font-family: "Trocchi", serif;
  align-self: flex-end;
}

.text-container .buttonClass:hover {
  border: 2px solid goldenrod;
  color: black;
  background: goldenrod;
}

.text-container .arrows-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;
  color: white;
  border: 2px solid white;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 30px; /* Adjust font size as needed */
  font-weight: 500;
  font-family: "Kanit", sans-serif;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container .arrows-container:hover {
  border: 2px solid white;
  color: black;
  background: white;
}

/* Additional media query for larger screens */
@media screen and (max-width: 1500px) {
  .nav__list-item {
    font-size: 6vh;
  }

  .text-container p {
    font-size: 22px;
    text-align: justify;
    padding-bottom: 4vh;
    text-align: start;
  }

  .text-container h2 {
    font-size: 70px;
  }

  .text-container button {
    padding: 15px 30px;
    font-size: 25px;
  }

  .text-container .buttonClass {
    font-size: 25px;
  }
}

/* Additional media query for larger screens */
@media screen and (max-width: 800px) {
  .nav__list-item {
    font-size: 5vh;
  }

  .text-container .arrows-container {
    visibility: hidden;
  }
  .text-container h2 {
    font-size: 50px;
    padding-bottom: 2%;
  }

  .text-container p {
    font-size: 25px;
  }

  .text-container button {
    padding: 15px 30px;
    font-size: 20px;
  }

  .text-container .buttonClass {
    font-size: 20px;
  }
}

/* Additional media query for larger screens */
@media screen and (max-width: 600px) {
  .text-container .arrows-container {
    visibility: hidden;
  }
  .text-container h2 {
    font-size: 40px;
    padding-bottom: 2%;
  }

  .text-container p {
    font-size: 17px;
  }

  .text-container button {
    padding: 15px 30px;
    font-size: 20px;
  }

  .text-container .buttonClass {
    font-size: 20px;
  }
}

.moving-bar-container {
  overflow-x: visible !important;
  position: relative;
  padding-bottom: 0.4%;
  padding-top: 0.4%;
  background-color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
}

.moving-bar {
  display: flex;
  overflow-x: visible !important;
  white-space: nowrap;
  animation: moveBar 20s linear infinite; /* Adjust the animation duration as needed */
}

.moving-bar span {
  overflow-x: visible !important;
  padding-right: 20px; /* Adjust the spacing between repeated text as needed */
}

@keyframes moveBar {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.uppercase {
  text-transform: uppercase;
}

.intro-container {
  position: relative;
  height: auto;
  background-image: url("./assets/texture.png");
  display: flex;
  padding-top: 1.5%;
  padding-bottom: 1%;
  padding-left: 11.5%;
  overflow: hidden; /* Hide the overflow of child elements */
}

.intro-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Make sure the overlay is above the imageTitle */
}

.imageTitle {
  width: 147px;
  margin-top: 1.5%;
  opacity: 0.7;
  filter: brightness(0) invert(100%);
  position: absolute;
  top: -20px;
  left: 9vw;
  transform: translateX(-50%);
}

.white-paragraph {
  color: white;
  margin: auto;
  padding-left: 50px;
  opacity: 0.9;
  padding-right: 80px;
  padding-top: 10px;
  font-size: 27px; /* Adjust as needed */
  text-align: justify;
  font-family: "Trocchi", serif;
  font-weight: 200;
  line-height: 1.05;
}
/* Media query for smaller screens */
@media only screen and (max-width: 1400px) {
  .white-paragraph {
    font-size: 20px;
    padding-left: 5% !important;
    padding-bottom: 4% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .white-paragraph {
    font-size: 20px;
    padding-left: 10% !important;
    padding-bottom: 4% !important;
  }
}
@media only screen and (max-width: 700px) {
  .white-paragraph {
    font-size: 15px;
    padding-left: 18% !important;
    padding-bottom: 4% !important;
  }
}

.full-height-container {
  height: auto;
  overflow-x: visible !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3eeea;
}

.grid-container-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: visible !important;
  gap: 4vw;
  height: 60%;
}

.grid-item {
  position: relative;
  overflow-x: visible !important;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.centered-content1 {
  overflow-x: visible !important;
  height: 100%;
}

.centered-content2 {
  overflow-x: visible !important;
  height: 100%;
}

.vertical-image {
  overflow-x: visible !important;
  width: 40px;
  height: 100%;
  background-image: url("./assets/african-design.jpg");
  background-size: cover;
  margin: 0 auto;
}

.nested-grid-container {
  overflow-x: visible !important;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 3vh;
  padding-top: 5%;
}

.nested-grid-container2 {
  overflow-x: visible !important;
  display: grid;
  gap: 2vw;
  grid-template-columns: repeat(3, 1fr); /* Updated to columns */
  margin-bottom: 4%;
}

.nested-grid-item-p {
  overflow-x: visible !important;
  border: 3px solid rgb(0, 0, 0);
  text-align: start;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #000;
  padding: 3rem;
  margin-top: 4.5%;
  margin-bottom: 4vh;
}

.nested-grid-item {
  overflow-x: visible !important;
  border: 1px solid #ccc;
  text-align: start;
  font-weight: bold;
  width: 20vw;
}

.nested-grid-item2 {
  overflow-x: visible !important;
  border: 1px solid rgb(66, 2, 2);
  text-align: start;
  font-weight: bold;
  color: goldenrod;
  background-color: rgb(66, 2, 2);
  width: 20vw;
  height: auto;
  padding: 3rem;
}

.nested-grid-item-gap {
  overflow-x: visible !important;
  margin-right: 5px;
}

.Sijlmassa {
  overflow-x: visible !important;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.2;
  text-align: justify;
}

.titleSijlmassa {
  overflow-x: visible !important;
  font-size: 40px;
  line-height: 1;
  padding-bottom: 2rem;
  font-family: "Trocchi", serif;
}

.video-container {
  overflow-x: visible !important;
  position: relative;
  width: 100%;
  height: 50%;
}

.video-controls {
  overflow-x: visible !important;
  position: absolute;
  top: 1%;
  left: 1%;
  padding-top: 0.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.play-pause-button {
  overflow-x: visible !important;
  background: transparent;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.video-player {
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
  pointer-events: none;
  z-index: 2;
}

@media (max-width: 1200px) {
  .nested-grid-item {
    display: none;
  }
  .nested-grid-item2 {
    width: 50vw;
    padding: 3.5rem;
  }
  .nested-grid-container {
    gap: 3vh;
  }

  .nested-grid-container2 {
    gap: 0vw;
  }
  .Sijlmassa {
    font-size: 20px;
  }

  .titleSijlmassa {
    font-size: 35px;
  }
}

@media (max-width: 800px) {
  .Sijlmassa {
    font-size: 15px;
  }

  .titleSijlmassa {
    font-size: 30px;
  }
}

.noise {
  display: flex;
}

.winners-container {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-bottom: 2%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.left-column {
  padding-left: 30%;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.big-title {
  color: white;
  font-size: 2.5em;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.1;
  font-family: "Trocchi", serif;
}

.small-title {
  color: white;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.3;
  text-align: justify;
}
.small-title2 {
  color: white;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.3;
  text-align: justify;
  padding-bottom: 6.5%;
}
.right-column {
  padding-right: 30%;
  justify-content: left;
  align-items: flex-end;
  align-self: flex-end;
}

.image-slider {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: left;
  align-items: flex-end;
  align-self: flex-end;
}

.slider-dots {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
}

.slider-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.slider-dot.active {
  background-color: goldenrod;
}

.image-slider img {
  width: 28vw;
  height: 28vw;
  border: 3px solid white;
  justify-content: left;
  align-items: flex-end;
  align-self: flex-end;
  margin-left: 5%;
  transition: opacity 1s ease-in-out; /* Add a transition for opacity */
}

.image-slider img.hidden {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 2px solid white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider-image.visible {
  opacity: 1;
}

/* Media query for small screens */
@media screen and (max-width: 1500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .image-slider img {
    width: 70vw;
    height: 50vw;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .right-column {
    padding-right: 0%;
  }
  .left-column {
    padding: 8% 15% 4% 15%;
    justify-content: left;
    align-items: flex-end;
    align-self: flex-end;
  }
  .slider-dots {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .small-title2,
  .small-title {
    padding-bottom: 0%;
    font-size: 22px;
  }
  .big-title {
    font-size: 40px;
  }
  .small-title2 {
    padding-bottom: 0%;
    font-size: 22px;
  }
}

/* Media query for small screens */
@media screen and (max-width: 900px) {
  .small-title2,
  .small-title {
    padding-bottom: 0%;
    font-size: 15px;
  }
  .big-title {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.placeholder-image {
  border: 3px solid black;
  filter: invert(100%);
  padding: 10px;
  width: 7%;
  height: 7%;
  margin-bottom: 0.5%;
  border-radius: 50px;
}

@media (max-width: 800px) {
  .placeholder-image {
    border: 3px solid black;
    filter: invert(100%);
    padding: 10px;
    width: 10%;
    height: 10%;
    margin-bottom: 1%;
    border-radius: 50px;
  }
}

.filmstrip-wrapper {
  overflow-x: visible !important;
  border-top: 1 px solid gray;
  border-bottom: 1 px solid gray;
  animation: filmstrip-animation 5s linear infinite;
  transform: perspective(90);
  position: relative;
}

.filmstrip {
  overflow-x: visible !important;
  background-color: black;
  white-space: nowrap;
  width: 200vw;
  padding-top: 2%;
}

@keyframes filmstripAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -100%
    ); /* Adjust the percentage based on the number of frames */
  }
}

.filmstrip-frame {
  overflow-x: visible !important;
  background-color: rgb(250, 249, 243);
  border-radius: 0px;
  display: inline-block;
  width: 16rem;
  height: 8rem;
  margin: 0 10px;
  border-radius: 15px;
  padding-top: 18px;
  padding-bottom: 20px;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}
.filmstrip-frame img {
  overflow-x: visible !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 8rem;
  object-fit: contain;
  filter: brightness(0.8);
}

@keyframes filmstrip-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-300px);
  }
}

.dashed-line {
  width: 100%;
  height: 2px;
  background-color: goldenrod; /* Set the background color directly */
  margin-top: 0.3%;
  padding-top: 0.01%;
  margin-bottom: 0.3%;
  opacity: 1;
}

.straight-line {
  width: 100%;
  height: 2px;
  background-color: rgb(250, 249, 243); /* Set the background color directly */
  margin-top: 0.3%;
  padding-top: 0.1%;
}

.dashed-line.top {
  top: 0;
}

.dashed-line.bottom {
  bottom: 0;
}

@media (max-width: 1000px) {
  .filmstrip-frame img {
    width: 60%;
  }
  .filmstrip-frame {
    width: 10rem;
    height: auto;
    padding: 0;
  }
  .dashed-line.top {
    top: 0;
    margin-bottom: 1%;
  }
}

.original-buttons-container {
  overflow-y: hidden !important;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px; /* Adjust the gap as needed */
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 3%;
  padding-top: 2%;
  margin: 0;
  justify-content: center;
  background-color: rgb(0, 0, 0);
}

.original-button:nth-child(1),
.original-button:nth-child(2),
.original-button:nth-child(4),
.original-button:nth-child(5) {
  overflow-y: hidden !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  width: auto;
  background: transparent;
  border: 2px solid goldenrod;
  color: rgb(255, 255, 255);
  border-radius: 100px; /* Added border radius */
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 20px !important;
  font-weight: 500;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  line-height: 1;
}

.original-button:nth-child(3) {
  overflow-y: hidden !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  width: auto;
  border: 2px solid white;
  background: white;
  border-radius: 100px; /* Added border radius */
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 20px !important;
  color:rgb(0, 0, 0);
  font-weight: 500;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  line-height: 1;
}

.original-button:hover {
  overflow-y: hidden !important;
  color: white;
  border: 2px solid white;
  background: transparent;
}

.original-black-screen {
  overflow-y: hidden !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0; /* Start with 0 height */
  background: rgb(255, 255, 255);
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's above other content */
  transition: height 0.3s; /* Add transition property for height */
}


.original-white-text {
  overflow-y: hidden !important;
  color: white;
  font-size: 24px;
  text-align: center;
}

.black-screen-active {
  overflow-y: hidden !important;
  height: 100%; /* Set the height when active to show the black screen */
}

.original-overlay {
  background: white;
  overflow-y: hidden !important;
  margin: 5% 10%;
  padding: 50px;
  width: 100%;
  border: 2px solid black;
}

.original-title {
  overflow-y: hidden !important;
  color: rgb(0, 0, 0);
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
}

.original-text {
  overflow-y: hidden !important;
  color: black;
  text-align: justify;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 1.2;
}

.red {
  overflow-y: hidden !important;
  color: goldenrod;
  font-weight: 600;
  font-size: 50px;
  padding-top: 6%;
}

@media (max-width: 1200px) {
  .original-title {
    font-size: 30px;
    line-height: 1;
    padding-bottom: 5%;
  }

  .original-text {
    font-size: 16px;
  }

  .red {
    font-size: 40px;
    padding-top: 10%;
    padding-bottom: 5%;
  }
  .original-overlay {
    background: white;
    margin: 5% 10%;
    padding: 50px;
    width: 100%;
    border: 2px solid black;
  }
  .original-buttons-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px; /* Adjust the gap as needed */
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-top: 2%;
    margin: 0;
    justify-content: center;
    background-color: rgb(0, 0, 0);
  }
  .original-button:nth-child(1),
  .original-button:nth-child(3),
  .original-button:nth-child(5) {
    font-size: 17px !important;
  }
}

@media (max-width: 900px) {
  .original-button {
    font-size: 15px;
    font-weight: 500;
    height: 3.5rem;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center;
    border-radius: 100;
  }

  .original-buttons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .original-button:nth-child(2),
  .original-button:nth-child(4),
  .original-button:nth-child(6) {
    display: none;
  }
  .original-button:nth-child(1),
  .original-button:nth-child(3),
  .original-button:nth-child(5) {
    font-size: 15px !important;
  }
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.section-news {
  overflow-x: visible !important;
  padding: 0% 15% 3.5% 15%;
  background-color: rgb(0, 0, 0);
}

.section-news .header-section {
  overflow-x: visible !important
}

.section-news .header-section .small-title {
  overflow-x: visible !important;
  position: relative;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 20px;
}

.header-section .small-title span {
  overflow-x: visible !important;
  display: inline-block;
  height: 2px;
  width: 20px;
  background-color: goldenrod;
  margin-right: 12px;
  transform: translateY(-6px);
}

.section-news .header-section .title {
  overflow-x: visible !important;
  font-weight: 700;
  color: #ffffff;
  font-size: 40px;
  font-family: "Trocchi", serif;
}

.section-news .single-news {
  overflow-x: visible !important;
  margin-top: 30px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);
  height: 700px;
}

.section-news .single-news img {
  overflow-x: visible !important;
  width: 100%;
}

.section-news .single-news .content {
  padding: 35px;
  overflow-x: visible !important;
}

.section-news .single-news .date {
  overflow-x: visible !important;
  display: inline-block;
  margin-bottom: 15px;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  padding: 7px 25px;
}

.section-news .single-news .title {
  overflow-x: visible !important;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Trocchi", serif;
  font-size: 25px;
  line-height: 1.1;
}

.section-news .single-news .title a {
  overflow-x: visible !important;
  text-decoration: none;
  color: goldenrod;
  font-family: "Trocchi", serif;
}

.section-news .single-news .title a:hover {
  overflow-x: visible !important;
  text-decoration: underline 3px;
}

.section-news .single-news .text {
  overflow-x: visible !important;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
}

.section-news .single-news .likes {
  overflow-x: visible !important;
  margin-right: 28px;
}

.section-news .single-news .likes,
.section-news .single-news .comments {
  overflow-x: visible !important;
  text-transform: uppercase;
  font-weight: 600;
  color: rgb(0, 0, 0);
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
}

.section-news .single-news .likesWord,
.section-news .single-news .commentsWord {
  overflow-x: visible !important;
  text-transform: uppercase;
  font-weight: 300;
  color: rgb(0, 0, 0);
  font-size: 18px;
  margin-right: 6%;
  padding: 0;
}

.single-news {
  background-color: white;
}

.custom-arrow {
  overflow-x: visible !important;
  position: absolute;
  transform: translateY(-50%);
  font-size: 30px; /* Adjust the font size as needed */
  color: white; /* Set the arrow color to white */
  background-color: transparent; /* Set the background color to transparent */
  margin-top: 40px;
  z-index: 97;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid white; /* Add white border */
  border-radius: 50%; /* Make the arrow circular */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s; /* Add a smooth transition for hover effect */
}

.custom-arrow:hover {
  overflow-x: visible !important;
  background-color: rgb(255, 255, 255);
  color: black; /* Change background color on hover to gold */
}

.next-arrow {
  overflow-x: visible !important;
  right: 0%;
  top: -1%;
}

.prev-arrow {
  overflow-x: visible !important;
  right: 3.5rem;
  top: -1%;
}

.custom-arrow span {
  overflow-x: visible !important;
  display: block;
}

@media (max-width: 1500px) {
  .section-news .header-section .title {
    overflow-x: visible !important;
    font-size: 35px;
  }
  .section-news .header-section .small-title {
    overflow-x: visible !important;
    font-size: 15px;
  }

  .section-news .single-news .content {
    overflow-x: visible !important;
    padding: 25px;
  }

  .section-news .single-news .title {
    overflow-x: visible !important;
    font-size: 25px;
  }

  .section-news .single-news .text {
    overflow-x: visible !important;
    font-size: 16px;
  }

  .section-news .single-news .likes,
  .section-news .single-news .comments {
    overflow-x: visible !important;
    font-size: 18px;
    padding-right: 3%;
  }
}

@media (max-width: 1200px) {
  .section-news .header-section .title {
    overflow-x: visible !important;
    font-size: 25px;
  }
  .section-news .header-section .small-title {
    overflow-x: visible !important;
    font-size: 15px;
  }
  .next-arrow {
    overflow-x: visible !important;
    right: 0%;
    top: -1%;
  }

  .prev-arrow {
    overflow-x: visible !important;
    right: 3.5rem;
    top: -1%;
  }
  .section-news .single-news .content {
    overflow-x: visible !important;
    padding: 25px;
  }

  .section-news .single-news .title {
    overflow-x: visible !important;
    font-size: 25px;
  }

  .section-news .single-news .text {
    overflow-x: visible !important;
    font-size: 16px;
  }

  .section-news .single-news .likes,
  .section-news .single-news .comments {
    overflow-x: visible !important;
    font-size: 18px;
  }

  .section-news .single-news .likes {
    overflow-x: visible !important;
    margin-right: 18px;
  }
}

@media (max-width: 600px) {
  .section-news .header-section .title {
    overflow-x: visible !important;
    font-size: 25px;
  }
  .section-news .header-section .small-title {
    overflow-x: visible !important;
    font-size: 15px;
  }
  .custom-arrow {
    overflow-x: visible !important;
    font-size: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 3px;
  }
  .next-arrow {
    overflow-x: visible !important;
    right: 0%;
    top: -1%;
  }

  .prev-arrow {
    overflow-x: visible !important;
    right: 2.6rem;
    top: -1%;
  }
  .section-news .single-news .content {
    overflow-x: visible !important;
    padding: 25px;
  }

  .section-news .single-news .title {
    overflow-x: visible !important;
    font-size: 22px;
  }

  .section-news .single-news .text {
    overflow-x: visible !important;
    font-size: 16px;
  }

  .section-news .single-news .likes,
  .section-news .single-news .comments {
    overflow-x: visible !important;
    font-size: 15px;
  }
}

footer {
  background-color: black;
  overflow-x: visible !important;
  font-family: "Lato", sans-serif;
  padding: 20px 0 210px 0;
  margin-top: 0 !important;
}

.footer-inner {
  overflow-x: visible !important;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 110px;
  padding-top: 10px;
  max-width: 1270px;
  position: relative;
  margin-top: 0 !important;
}

.footer-item {
  overflow-x: visible !important;
  float: left;
  margin: 0 3.5% 0 0;
  padding: 0;
  margin-top: 0 !important;
}

.footer-item:nth-of-type(4) {
  overflow-x: visible !important;
  display: none;
}

.footer-item:nth-of-type(7) {
  overflow-x: visible !important;
  float: right;
  margin-right: 0;
}

.footer-button {
  overflow-x: visible !important;
  color: white;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  transition: 1s;
  transition-delay: 0.2s;
  padding: 14px;
}

.footer-button:after {
  overflow-x: visible !important;
  content: "";
  position: absolute;
  top: 45px;
  right: 13px;
  background-color: white;
  height: 1px;
  width: 86px;
  transition: 0.6s;
}

.footer-button:hover {
  overflow-x: visible !important;
  color: black;
  background-color: white;
}

.footer-button:hover:after {
  overflow-x: visible !important;
  width: 112px;
  right: 0px;
}

h1 {
  font-weight: 900;
  color: white;
  font-size: 24px;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 10px;
}

.h2class {
  font-weight: 300;
  line-height: 1.8;
  font-size: 13px;
  color: #d1d1d1;
  letter-spacing: 0.03em;
  padding: 15px 0 0 0;
}

.color {
  color: white;
  font-weight: 400;
}

h3 {
  font-weight: 400;
  font-size: 13px;
  color: white;
  margin: 0;
  padding-bottom: 9px;
  letter-spacing: 0.03em;
}

h3.desktop {
  padding-top: 30px;
}

ul {
  line-height: 1.8;
  list-style-type: none;
  padding: 0;
}

li {
  font-weight: 300;
  font-size: 13px;
  color: #d1d1d1;
  letter-spacing: 0.03em;
}

p {
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  line-height: 1.8;
  letter-spacing: 0.03em;
}

a {
  text-decoration: none;
  color: #d1d1d1;
}

a:hover {
  color: white;
}

.desktop {
  display: auto;
}

.footer-inner:after {
  content: "© 2024 Rabii Lamharzi Alaoui. All Rights Reserved.";
  font-weight: 300;
  letter-spacing: 0.03em;
  font-size: 13px;
  color: #d1d1d1;
  position: absolute;
  top: 260px;
  clear: both;
  display: block;
}

@media (max-width: 1400px) {

  .footer-button {
    visibility: hidden;
  }

}

@media (max-width: 1150px) {
  footer {
    padding: 50px 0 70px 0 !important;
  }

  .footer-inner {
    border-bottom: 1px solid #333;
    padding-bottom: 490px;
    margin-bottom: 50px;
  }

  .footer-item {
    margin: 0 0 42px 0;
    width: 50%;
  }

  .footer-button {
    visibility: hidden;
  }

  .footer-item:nth-of-type(1) {
    border-bottom: 1px solid #333;
    padding-bottom: 32px;
    float: none;
    width: 100%;
  }

  .footer-item:nth-of-type(2) {
    clear: both;
  }

  .footer-item:nth-of-type(4) {
    clear: both;
    display: block;
  }

  .footer-item:nth-of-type(6) {
    clear: both;
  }

  .footer-item:nth-of-type(7) {
    clear: both;
    float: left;
    margin: 20px 0 0 -13px;
  }

  .desktop {
    display: none;
  }

  .footer-inner:after {
    top: 730px;
  }
}

.footer-logo1 {
  width: 30%;
  filter: invert(100%);
}

@media (max-width: 1150px) {
  .footer-logo1 {
    width: 8rem;
  }
  .footer-item {
    margin: 30px 0 42px 0;
    width: 50%;
  }
  .footer-inner:after {
    top: 100%;
    clear: both;
    display: block;
  }
  .footer-button {
    visibility: hidden;
  }
}

.custom-modal {
  top: -100%;
  animation: slideIn 0.2s ease-out forwards;
}

@keyframes slideIn {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.red-bg.active {
  background-color: red;
}

.large-font {
  font-size: xxx-large;
}

.top-20 {
  position: relative;
  top: 20vh;
}

ion-icon.active {
  animation: like 0.5s 1;
  fill: red;
  stroke: none;
}

ion-icon {
  fill: transparent;
  stroke: black;
  stroke-width: 30;
  transition: all 0.5s;
}

@-webkit-keyframes like {
  0% { transform: scale(1); }
  90% { transform: scale(1.2); }
  100% { transform: scale(1.1); }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-confirm-button {
  color: black !important;
  background-color: goldenrod !important;
  border-radius: 0 !important;
}

.slider {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: black;
}

.slider input[type="radio"] {
  position: relative;
  margin: 0 1rem 1rem 0;
  transform: scale(1.5);
  z-index: 100;
}

.slider input[type="radio"]:checked + .slider__content {
  transition-delay: 0.5s;
  background-color: black;
  clip-path: circle(100vw at center);
}

.slider__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  clip-path: circle(0px at center);
}

.slider__content img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider__content .slider__description {
  position: relative;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  max-width: 1000px;
}

.slider__content .slider__description h1 {
  font-size: 5rem;
}

.slider__content .slider__description p {
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: 1rem 0 2rem 0;
}

.slider__content .slider__description a {
  display: inline-block;
  padding: 10px 15px;
  background-color: white;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: 0.5s;
}

.slider__content .slider__description a:hover {
  opacity: 0.5;
}

.transparent-background {
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity here */
  overflow-y: hidden !important;
}

.transparent-background-overlay {
  overflow-y: hidden !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here */
  z-index: 2;
}

.transparent-background-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
}

.transparent-background-overlay img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 25px; /* Adjust margin as needed */
}

.slide img {
  width: inherit;
  height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  object-fit: contain;
}

.slide {
  white-space: nowrap;
  width: 1354px;
  height: 727px;
  overflow: hidden;
  position: relative;
}

.center {
  margin: auto;
}

@media (max-width: 1500px) {
  .slide {
    width: 1000px;
    height: 500px;
  }
}

@media (max-width: 1200px) {
  .slide {
    width: 600px;
    height: 400px;
  }
}

@media (max-width: 800px) {
  .slide {
    width: 450px;
    height: 300px;
  }
}

@media (max-width: 600px) {
  .slide {
    width: 250px;
    height: 250px;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 100px;
  color: white;
  cursor: pointer;
}

.left {
  left: 50px;
}

.right {
  right: 50px;
}

.newsletter-container {
  background-color: black;
  color: white;
  border-radius: 0;
  width: 100%;
  margin-top: 5px !important;
  padding-top: 1% !important;
}

form {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "DM Sans", sans-serif;
}

.newsletter-container h2 {
  margin-bottom: 10px;
  margin-top: 0 !important;
  text-align: center;
}

.newsletter-form {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  margin: 0 !important;
  padding: 0 !important;
  align-self: center;
  justify-content: center;
}

.newsletter-form input {
  flex: 0.54; /* Take up all available space */
  align-self: center;
  justify-content: center;
  padding: 15px 0px 15px 30px;
  border-radius: 0;
  border: 0;
  border-radius: 200px;
  margin: 0 !important;
  overflow: hidden;
  font-weight: 500;
  font-family: "Kanit", sans-serif;
  font-size: larger;
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

.newsletter-form button {
  flex: 0.155; 
  cursor: pointer;
  background-color: goldenrod;
  border: 1px solid goldenrod;
  color: black;
  margin: 20px 0px 1% 20px;
  padding: 15px;
  border-radius: 500px;
  overflow: hidden;
  font-weight: 500;
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  font-size: larger;
  transition: background 0.3s, color 0.3s;
}

.newsletter-form button:hover {
  flex: 0.155; 
  cursor: pointer;
  background-color: white;
  border: 1px solid white;
  color: black;
}

/* Your existing styles here */

/* Media query for smaller screens */
@media screen and (max-width: 1000px) {
  .newsletter-form {
    flex-direction: column; /* Stack elements vertically */
    text-align: center; /* Center text in the column */
  }

  .newsletter-form input,
  .newsletter-form button {
    width: 80%; /* Make both input and button take up 100% width */
    box-sizing: border-box; /* Include padding and border in the width */
    margin: 0;
  }

  .newsletter-form input {
    margin-top: 60px !important;
    margin-bottom: 20px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .newsletter-form button {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.highlighted-section {
  background-color: rgba(255, 255, 255, 0.25);
  transition: opacity 1s ease-in-out; 
  padding: 0px;
}

@keyframes drop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.drop {
  animation: drop 5s ease-out; /* Adjust the duration and timing function as needed */
}

.video-container {
  position: relative;
}

.control-buttons {
  position: absolute;
  top: 10px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.control-button {
  width: 65px; /* Adjust as needed for your preferred size */
  height: 65px; /* Adjust as needed for your preferred size */
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  margin: 10px;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  object-fit: cover; 
  padding: 1% 15% 0% 15%;
  background-color: black;
}

.gallery-image {
  width: calc(25% - 1.5%); /* Adjust width for 4 images per row */
  height: 410px; /* Set a fixed height for uniformity */
  object-fit: cover; /* Ensure all images have the same width and height */
  border: 3px solid white; /* Add white border around each image */
  margin-bottom: 1.5%; /* Add space between rows */
  transition: transform 0.3s ease-in-out; /* Add transition for smooth scaling effect */
}

.gallery-image:hover {
  transform: scale(1.02); /* Scale the image on hover */
}

@media screen and (max-width: 1500px) {
  .gallery-image {
    width: calc(33.33% - 1%); /* Adjust width for 3 images per row */
    height: 500px;
  }
}

@media screen and (max-width: 1300px) {
  .gallery-image {
    width: calc(50% - 1%); /* Adjust width for 2 images per row */
    height: 600px;
  }
  .image-gallery {
    padding: 10%;
  }
}

@media screen and (max-width: 1100px) {
  .gallery-image {
    width: calc(100% - 1%); /* Adjust width for 2 images per row */
    height: 800px;
  }
  .image-gallery {
    padding: 20%;
  }
}

@media screen and (max-width: 900px) {
  .gallery-image {
    width: calc(100% - 1%); /* Adjust width for 2 images per row */
    height: 600px;
  }
  .image-gallery {
    padding: 20%;
  }
}

@media screen and (max-width: 700px) {
  .gallery-image {
    width: calc(100% - 1%); /* Adjust width for 2 images per row */
    height: 500px;
  }
  .image-gallery {
    padding: 20%;
  }
}

@media screen and (max-width: 600px) {
  .gallery-image {
    width: calc(100% - 1%); /* Adjust width for 2 images per row */
    height: 500px;
  }
  .image-gallery {
    padding: 20%;
  }
}

@media screen and (max-width: 500px) {
  .gallery-image {
    width: calc(100% - 1%); /* Adjust width for 2 images per row */
    height: 400px;
  }
  .image-gallery {
    padding: 20%;
  }
} 

.imim-overlay {
  position: fixed;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px); /* Add blur effect */
  z-index: 10000;
  overflow: auto;
  border-radius: 20px;
}

@media screen and (max-width: 1100px) {
  .imim-overlay {
    position: fixed;
    top: 20%;
    left: 0%;
    right: 0%;
    width: 0%;
    height: 0%;
  }
}

@media screen and (max-width: 500px) {
  .imim-overlay {
    position: fixed;
    top: 20%;
    left: 0%;
    right: 0%;
    width: 0%;
    height: 0%;
  }
} 

.imim-overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imim-content-row {
  display: flex;
  align-items: center;
}

.selected-image {
  max-width: 50%;
  max-height: 80vh;
  margin-right: 30px;
}

.imim-overlay h1 {
  margin-bottom: 10px;
}

.imim-overlay p {
  margin-bottom: 0;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.close-icon-overlay{
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 25px; /* Adjust margin as needed */
  width: 25px;
  z-index: 60;
  filter: brightness(0%);
}

.imim-title{
  font-size: 40px;
  color: black;
  font-weight: 600;
  font-family: "Trocchi", serif;
}

.imim-paragraph{
  font-size: large;
  font-weight: 600;
  line-height: normal;
  color: black;
  text-align: justify;
}

.imim-paragraph-div{
  width: 100%
}

.imim-text{
  width: 30%;
}

.film-title{
  font-weight: 600;
  font-size: 40px;
  font-family: "Trocchi", serif;
}

.winners-image-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.winners-image-container img {
  max-width: calc(100% / 5); /* Three images per row */
}

/* Media Query for smaller screens */
@media only screen and (max-width: 900px) {
  .winners-image-container img {
    max-width: calc(100% / 3); /* Two images per row on smaller screens */
  }
}

.workshops-image-container {
  display: grid;
  grid-template-columns: repeat(6, 0fr);
  justify-content: center;
  visibility: visible !important;
}

.workshop-image {
  width: 200px;
  height: 200px;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 1600px) {
  .workshops-image-container {
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    justify-content: center;
  }
  .workshop-image-1 {
    display: none;
  }
  .workshop-image-2 {
    display: none;
  }
  .workshop-image-3 {
    display: none;
  }
  .workshop-image-4 {
    display: none;
  }
}

/* Media Query for smaller screens */
@media only screen and (max-width: 1200px) {
  .workshops-image-container {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    justify-content: center;
  }
  .workshop-image-4 {
    display: none;
  }
  .workshop-image-5 {
    display: none;
  }
  .workshop-image-6 {
    display: none;
  }
}

/* Media Query for smaller screens */
@media only screen and (max-width: 900px) {
  .workshop-image {
    display: none;
  }
}

.conference-image-container {
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  justify-content: center;
}

.conference-image {
  width: 220px;
  height: 220px;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 1200px) {
  .conference-image-container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    justify-content: center;
  }
  .conference-image-1 {
    display: none;
  }
  .conference-image-2 {
    display: none;
  }
}

/* Media Query for smaller screens */
@media only screen and (max-width: 900px) {
  .conference-image {
    display: none;
  }
}
.react-gallery {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden; /* Eliminate scroll bars */
  justify-content:center;
  align-items:center;
  overflow: hidden;
  padding-top: 0.5%;
  padding-bottom: 3.5%;
}

.react-image-container {
  margin: 5px;
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.react-thumbnail {
  max-width: 22.7vw; /* Ensure images don't exceed container width */
  height: auto;
  transition: transform 0.3s ease-in-out;
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.react-thumbnail:hover {
  transform: scale(1.1);
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.react-modal {
  max-width: 80%;
  max-height: 80%;
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.react-modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  justify-content:center;
  align-items:center;
  overflow: hidden;
}

.hommage-div{
  display: flex;
  flex-direction: row;
  background-color: goldenrod;
  margin-left: 15%;
  margin-right: 15%;
  padding: 2% 2% 2% 2%;
  border-radius: 30px;
}

.hommage-text{
  display: flex;
  flex-direction: column;
}

.hommage-title{
  font-size: 35px;
  font-family: "Trocchi", serif;
  font-weight: 600;
}

.hommage-para{
  font-size: 19px;
  font-family: "DM Sans", sans-serif;
  line-height: normal;
  padding-top: 1%;
  padding-right: 4%;
}

.hommage-images{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hommage-images img{
  width: 48.5%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  filter: sepia(60%);
}

@media (max-width: 1500px) {
  .hommage-div{
    display: flex;
    flex-direction: column;
    padding: 3% 3% 3% 3%;
  }
  .hommage-images img{
    width: 48.5%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
    margin-top: 2%;
  }
}

@media (max-width: 1000px) {
  .hommage-div{
    background-color: goldenrod;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 2%;
    padding: 3% 3% 3% 3%;
    border-radius: 30px;
  }
  .hommage-images img{
    width: 48%;
    margin-top: 3%;
  }
  .hommage-title{
    font-size: 30px;
    font-family: "Trocchi", serif;
    font-weight: 600;
  }
  
  .hommage-para{
    font-size: 15px;
    font-family: "DM Sans", sans-serif;
    line-height: normal;
    text-align: justify;
    padding-top: 1%;
  }
}

@media (max-width: 700px) {
  .hommage-div{
    background-color: goldenrod;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 2%;
    padding: 5% 5% 5% 5%;
    border-radius: 30px;
  }
  .hommage-images img{
    width: 48%;
    margin-top: 5%;
  }
  .hommage-title{
    font-size: 30px;
    font-family: "Trocchi", serif;
    font-weight: 600;
  }
  
  .hommage-para{
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    line-height: normal;
    text-align: justify;
    padding-top: 1%;
  }
}